import { Loader } from '@googlemaps/js-api-loader'
import { TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'

type GoogleAddressSearchProps = {
  onChange: (place?: google.maps.places.PlaceResult) => void
  label?: string
  placeholder?: string
  autoFocus?: boolean
}

const GoogleAddressSearch: React.FC<GoogleAddressSearchProps> = ({
  onChange,
  label = 'Search for your address',
  placeholder = 'Enter a location',
  autoFocus,
}) => {
  const autoCompleteInputRef = useRef(null as null | HTMLInputElement)
  const autoCompleteRef = useRef(null as null | google.maps.places.Autocomplete)
  const [libraryLoaded, setLibraryLoaded] = useState(false)

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    })
    loader.importLibrary('places').then(async () => {
      setLibraryLoaded(true)
    })
  }, [])

  const handlePlaceSelect = useCallback(() => {
    const addressObject = autoCompleteRef?.current?.getPlace()
    onChange(addressObject)
  }, [onChange])

  useEffect(() => {
    if (!libraryLoaded || !autoCompleteInputRef.current) return
    if (!window.google) throw new Error('Google Maps script not initialized')

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      autoCompleteInputRef?.current,
      { types: ['geocode'] },
    )
    autoCompleteRef.current.setFields([
      'address_components',
      'formatted_address',
      'geometry',
      'place_id',
    ])
    autoCompleteRef.current.addListener('place_changed', () =>
      handlePlaceSelect(),
    )
  }, [libraryLoaded])

  return (
    <div className="search-location-input">
      <TextField
        id="google-address-autocomplete-input"
        inputRef={autoCompleteInputRef}
        label={label}
        placeholder={placeholder}
        fullWidth
        autoComplete="off"
        autoFocus={autoFocus}
      />
    </div>
  )
}

export default GoogleAddressSearch
