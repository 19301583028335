/** Validate using validate.js! */
import { isValidISODateString, isValidDate } from 'iso-datestring-validator'
// utils
import get from 'lodash/get'
import { DateTime } from 'luxon'
import url from 'url'
import Validate, { ValidateOption } from 'validate.js'

export const INTERVAL_PARTS = [
  'years',
  'months',
  'days',
  'hours',
  'minutes',
  'seconds',
]

/*
  CUSTOM VALIDATORS
*/

type CustomValidator = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes?: any,
) => string | string[] | undefined

const validators: Record<string, CustomValidator> = {}

/** Validate a URL */
validators.validUrl = function (linkUrl) {
  if (
    /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/.test(
      linkUrl,
    )
  ) {
    return
  }
  const parsedUrl = url.parse(linkUrl)
  if (!parsedUrl.protocol)
    return 'is missing a protocol (usually http or https)'
  if (!parsedUrl.hostname) return 'is missing a hostname'
}

// make a field required contingent on the value of another field
validators.contingency = function (value, options, key, attributes) {
  let values = options.values || true // default to just being required
  if (!Array.isArray(values) && values !== true) values = [values] // coerce values into an array
  const message =
    options.message ||
    `is required if ${Validate.prettify(options.field)} is set${
      values !== true ? ` to ${values.join(' or ')}` : ''
    }`
  const fieldValue = get(attributes, options.field)
  if (!options.field) return // if we don't have a field to check against, this is pointless
  if (!fieldValue) return // if we don't have a field value then we don't have a contingency
  if (values !== true && !values.includes(fieldValue)) return // contingency on a range of values
  return Validate.single(value, { presence: { message } }) // if there's a value in this field, then we've satisfied the contingency
}

// validate a UUID
validators.uuid = function (uuid) {
  if (
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
      uuid,
    )
  )
    return
  return 'is not a valid UUID/GUID'
}

// validate if a person's name looks OK
validators.personName = function (name) {
  if (/^\s|\s$/.test(name)) return 'must not have spaces at the start or end'
  if (
    /^$|^[^0-9\u0021-\u0026\u0028-\u002C\u002E-\u0040\u2000-\u206F\u007B-\u007F]{1,30}$/.test(
      name,
    )
  )
    return
  if (name.length > 30) return 'must be no more than 30 characters'
  return 'must not contain numbers or special characters'
}

validators.ukPostcode = function (postcode) {
  const postcodeRegEx = /^$|(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))/
  if (postcodeRegEx.test(postcode)) return
  return 'must be a valid UK postcode'
}

validators.auPostcode = function (postcode) {
  const postcodeRegEx = /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/
  if (postcodeRegEx.test(postcode)) return
  return 'must be a valid Australian postcode'
}

validators.zipCode = function (zipCode) {
  const zipcodeRegEx = /^\d{5}(?:[-\s]\d{4})?$/
  if (zipcodeRegEx.test(zipCode)) return
  return 'must be a valid US Zip Code'
}

validators.integerOrDecimal = (number, options) => {
  if (!number) return 'must be a valid number'
  if (Array.isArray(options.decimals) && options.decimals.length !== 2)
    throw new Error(
      `decimals option must be a number, or an array of exactly two numbers`,
    )
  const decimals = Array.isArray(options.decimals)
    ? options.decimals
    : [options.decimals || 2]
  // Allow negative numbers
  const decimalRegex = new RegExp(
    `^-?[\\d]+([.][\\d]{${decimals.join(',')}})?$`,
  )
  if (decimalRegex.test(number.toString())) return
  return (
    options.message ||
    `must be an integer or a number with ${
      decimals.length === 1
        ? `exactly ${decimals[0]}`
        : `between ${decimals[0]} and ${decimals[1]}`
    } decimal digits`
  )
}

validators.isoDateString = (input, options) => {
  if (isValidISODateString(input) || isValidDate(input)) return
  return options.message || 'must be a valid ISO 8601 date string'
}

validators.interval = (input, options) => {
  if (
    input &&
    typeof input === 'object' &&
    INTERVAL_PARTS.some((part) => input[part])
  )
    return
  return options.message || 'must be an Interval record'
}

// add the custom validators to the main validate object
Object.assign(Validate.validators, validators)

// Extend the datetime validator with custom parse/format functions
Validate.extend(Validate.validators.datetime, {
  parse: (value: unknown) => {
    if (typeof value === 'string') {
      return DateTime.fromISO(value, { zone: 'utc' }).toMillis()
    } else if (value instanceof DateTime) {
      return value.toMillis()
    }
    throw new Error('Cannot validate ')
  },
  format: (
    value: number,
    options: { dateOnly?: boolean; [x: string]: unknown },
  ) => {
    const dt = DateTime.fromMillis(value).toLocal()
    return options.dateOnly ? dt.toISODate() : dt.toISO()
  },
})

export default Validate

export function validateSingle(
  value: unknown,
  constraints: unknown,
  options?: ValidateOption,
): string | string[] | undefined | null {
  return Validate.single(value, constraints, options)
}

type GetValidationErrorsOpts = {
  skip: boolean
  max: number
}

// utility for getting validation state from a validation object
export function getValidationState(
  fields: string[],
  validation: unknown,
  opts: GetValidationErrorsOpts,
): string | null {
  const errors = getValidationErrors(fields, validation, opts)
  const defaults = { skip: false, errorClass: 'error', successClass: 'success' }
  const options = Object.assign({}, defaults, opts)
  const { skip, successClass, errorClass } = options
  if (skip) return null
  // if any of our fields have validation errors,
  // return the error class, otherwise return success
  return errors && errors.length ? errorClass : successClass
}

// utility for getting validation errors from a validation object
export function getValidationErrors(
  fields: string[],
  validation: unknown,
  opts: GetValidationErrorsOpts,
): string[] {
  const defaults = { skip: false, max: 100 }
  const options = Object.assign({}, defaults, opts)
  const { skip, max } = options
  // if we're skipping, get out of here
  if (skip) return []
  if (typeof fields === 'string') fields = [fields]
  let errors: string[] = []
  fields.forEach((field) => {
    errors = errors.concat(get(validation, field, []))
  })
  if (!errors.length) return []
  // return the maximum number of errors
  return errors.slice(0, max)
}

export { validators, Validate }
