import { AddressUnsaved } from '../AddressSelector/types'
import {
  PlaceResult,
  AddressComponent,
  AddressComponentName,
  AddressComponentsMap,
} from './types'

const COMPONENTS_USE_SHORT_NAME = ['country']

const CITY_ORDERING: Readonly<Record<string, AddressComponentName[]>> = {
  DEFAULT: [
    'neighborhood',
    'colloquial_area',
    'locality',
    'postal_town',
    'administrative_area_level_2',
  ],
  US: [
    'locality',
    'neighborhood',
    'colloquial_area',
    'postal_town',
    'administrative_area_level_2',
  ],
}

const COUNTRIES_USE_ADMINISTRATIVE_AREA_LEVEL_2 = ['GB']

function flattenAddressComponents(
  addressComponents: AddressComponent[],
): AddressComponentsMap {
  const addressComponentsFlat: AddressComponentsMap = {}
  for (const component of addressComponents) {
    const type = component.types[0] as AddressComponentName
    if (COMPONENTS_USE_SHORT_NAME.includes(type)) {
      addressComponentsFlat[type] = component.short_name
    } else {
      addressComponentsFlat[type] = component.long_name
    }
  }
  return addressComponentsFlat
}

export function formatGooglePlaceAsAddress(
  place: PlaceResult,
): AddressUnsaved | void {
  if (!place.address_components) return
  const address = flattenAddressComponents(place.address_components)
  // street number
  let houseNumber =
    address.street_number || address.premise || address.subpremise
  // address lines
  let addressLine1 = address.route || address.intersection

  // Extract house number from route if it didn't show up individually
  const houseNumberRegex = /^(\d+\w*)\s(.*)$/
  if (!houseNumber && addressLine1) {
    const matches = addressLine1.match(houseNumberRegex)
    if (matches && matches[1]) {
      houseNumber = matches[1]
      addressLine1 = matches[2]
    }
  }
  // city
  const ordering = address.country
    ? CITY_ORDERING[address.country] || CITY_ORDERING.DEFAULT
    : CITY_ORDERING.DEFAULT
  const cityField = ordering.find((field) => address[field])
  let city = cityField ? address[cityField] : undefined
  // region
  let region: string | undefined =
    address.country &&
    COUNTRIES_USE_ADMINISTRATIVE_AREA_LEVEL_2.includes(address.country)
      ? address.administrative_area_level_2
      : address.administrative_area_level_1

  // some countries (e.g. TW) are small, and so cities are the top-level admin division
  // if there's no city, but there is a region, use the region as the city
  if (region && !city) {
    city = region
    region = undefined
  }
  // postal code
  const postalCode =
    address.postal_code ||
    [address.postal_code_prefix, address.postal_code_suffix]
      .filter((a) => a)
      .join(' ')
      .trim()
  // country code
  const countryCode = address.country
  // coordinates
  const latitude = place.geometry?.location?.lat()
  const longitude = place.geometry?.location?.lng()
  // google place ID
  const googlePlaceId = place.place_id

  // return formatted address
  const Address = {
    houseNumber,
    addressLine1,
    city,
    region,
    postalCode,
    countryCode,
    latitude,
    longitude,
    googlePlaceId,
  }
  return Address
}
