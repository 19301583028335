import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { enabledPaymentRecipients, PaymentGateway, PaymentMethod, RecurrencePeriod } from "@aletheia/graphql";
/**
 * Returned as a function so that we always return a fresh object. The
 * alternative is a bunch of ugly cloning/spreading
 */ export function getPaymentConstraints(isRecurring, loginMode) {
    var maxDecimals = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 2;
    var baseConstraints = {
        recipient: {
            presence: true,
            inclusion: enabledPaymentRecipients
        },
        amount: {
            presence: true,
            numericality: {
                greaterThanOrEqualTo: 5,
                lessThanOrEqualTo: 1e9,
                strict: true
            },
            integerOrDecimal: {
                decimals: [
                    1,
                    maxDecimals
                ]
            }
        },
        currencyCode: {
            presence: true,
            format: {
                pattern: /^[A-Z]{3}$/
            }
        },
        gateway: {
            presence: true,
            inclusion: [
                PaymentGateway.Stripe,
                PaymentGateway.Gocardless,
                PaymentGateway.Offline,
                PaymentGateway.Chariot, 
            ]
        },
        paymentMethod: {
            presence: true,
            inclusion: Object.values(PaymentMethod)
        }
    };
    var constraints = baseConstraints;
    if (isRecurring) {
        constraints = _object_spread_props(_object_spread({}, constraints), {
            period: {
                presence: {
                    message: "/ donation frequency must be selected"
                },
                inclusion: [
                    RecurrencePeriod.Month
                ]
            },
            periodIndex: {
                numericality: {
                    greaterThanOrEqualTo: -1,
                    onlyInteger: true,
                    strict: true
                },
                contingency: {
                    field: "period",
                    values: [
                        RecurrencePeriod.Month
                    ]
                }
            }
        });
    }
    if (loginMode === "user") {
        constraints = _object_spread_props(_object_spread({}, constraints), {
            personId: {
                presence: {
                    message: "^You need to be logged in."
                }
            }
        });
    }
    return constraints;
}
export var GoCardlessConstraints = {
    "gatewayData.mandate": {
        presence: {
            message: "^You must select a bank account"
        },
        format: {
            pattern: /^MD\w+$/,
            message: "must be a valid GoCardless Mandate ID"
        }
    }
};
export var ChariotConstraints = {
    "gatewayData.fundId": {
        presence: {
            allowEmpty: false,
            message: "^Please complete your DAF transaction via DAFpay Network"
        }
    }
};
// Check that the string does't contain "and" or "&"
// by using a negative lookahead at the start of the string
var INDIVIDUAL_NAME_REGEX = /^(?!.*(\band\b|&)).+$/i;
export var UKAddressConstraints = {
    firstName: {
        presence: {
            allowEmpty: false,
            message: "^You must include your first name in the address"
        },
        length: {
            minimum: 2,
            tooShort: "can't be initials. Please provide your full name for Gift Aid"
        },
        format: {
            pattern: INDIVIDUAL_NAME_REGEX,
            message: "cannot contain multiple names. Please provide an individual name for Gift Aid"
        }
    },
    lastName: {
        presence: {
            allowEmpty: false,
            message: "^You must include your last name in the address"
        },
        length: {
            minimum: 2,
            tooShort: "can't be initials. Please provide your full name for Gift Aid"
        },
        format: {
            pattern: INDIVIDUAL_NAME_REGEX,
            message: "cannot contain multiple names. Please provide an individual name for Gift Aid"
        }
    },
    houseNumber: {
        presence: true
    },
    addressLine1: {
        presence: true
    },
    postalCode: {
        presence: true,
        ukPostcode: true
    },
    countryCode: {
        presence: true,
        format: {
            pattern: /^GB$/i,
            message: "must be set to the United Kingdom"
        }
    }
};
var BillingAddressConstraints = {
    houseNumber: {
        presence: true
    },
    addressLine1: {
        presence: true
    },
    postalCode: {
        presence: true
    },
    countryCode: {
        presence: true
    }
};
export var IndividualBillingAddressConstraints = _object_spread({
    firstName: {
        presence: {
            allowEmpty: false,
            message: "^You must include your first name in the address"
        }
    },
    lastName: {
        presence: {
            allowEmpty: false,
            message: "^You must include your last name in the address"
        }
    }
}, BillingAddressConstraints);
export var OrganizationBillingAddressConstraints = _object_spread({
    organizationName: {
        presence: {
            allowEmpty: false,
            message: "^You must include your organisation name in the address"
        }
    }
}, BillingAddressConstraints);
export function getTemporaryPersonConstraints() {
    return {
        email: {
            presence: true,
            email: true
        },
        sessionToken: {
            presence: true,
            uuid: true
        },
        tokenExpiresAt: {
            datetime: {
                earliest: new Date().toISOString(),
                message: "^Token has expired"
            }
        }
    };
}
