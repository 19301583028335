import { TPerson, TTemporaryPerson } from '@aletheia/graphql'

import { AddressSelectorProps } from '.'

/** An unsaved address */
export type AddressUnsaved = {
  firstName?: string | null
  lastName?: string | null
  houseNumber?: string | null
  addressLine1?: string | null
  addressLine2?: string | null
  city?: string | null
  region?: string | null
  postalCode?: string | null
  countryCode?: string | null
  latitude?: number | null
  longitude?: number | null
  googlePlaceId?: string | null
  organizationName?: string | null
}

export function isPerson(
  Person: AddressSelectorProps['Person'],
): Person is TPerson {
  return !(Person as TTemporaryPerson).sessionToken
}
