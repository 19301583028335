/**
 * Global CSS styles
 *
 * Use to target tricky CSS changes that can't be handled using the overrides
 * prop of the Material UI theme
 *
 * See https://material-ui.com/styles/advanced/#global-css
 */
import { createTheme } from '@material-ui/core'
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides'
import {
  ToggleButtonClassKey,
  ToggleButtonGroupClassKey,
} from '@material-ui/lab'
import { CSSProperties } from '@material-ui/styles'

const theme = createTheme()

export const globalStyles: Record<string, CSSProperties> = {
  // element definitions
  body: {
    backgroundColor: '#FFF',
  },
  code: {
    backgroundColor: theme.palette.grey[100],
    padding: '0.5em 1em',
    display: 'inline-block',
    fontFamily:
      'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;',
  },
  // utility classes
  '.left': {
    textAlign: 'left',
  },
  '.right': {
    textAlign: 'right',
  },
  '.center': {
    textAlign: 'center',
  },
  '.info': {
    color: theme.palette.info.main,
  },
  '.success': {
    color: theme.palette.success.main,
  },
  '.warning': {
    color: theme.palette.warning.main,
  },
  '.error': {
    color: theme.palette.error.main,
  },
  // make link anchors push beyond the navbar
  '*:target': {
    paddingTop: theme.spacing(12),
    marginTop: -theme.spacing(12),
  },
  // component-specific styles
  // ensure Google Places Autocomplete sits above Modals/Dialogs
  '.pac-container': {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zIndex: '1400 !important' as any,
  },
  // lazy-load-image styles
  '.lazy-load-image-background': {
    lineHeight: 0,
  },
  '.width-half-md': {
    width: '50%',
    height: 'auto',
    margin: '0 25%',
  },
  '.width-two-thirds-md': {
    width: '66.67%',
    height: 'auto',
    margin: '0 16.67%',
  },
  '.width-one-third-md': {
    width: '33.33%',
    height: 'auto',
    margin: '0 33.33%',
  },
  details: {
    '&[open] > summary': {
      marginBottom: theme.spacing(2),
    },
    marginBottom: theme.spacing(2),
  },
  summary: {
    '& > :first-child': {
      display: 'inline',
      marginTop: 0,
      '& > .MuiLink-underlineHover': {
        display: 'none',
      },
    },
  },
  '.well': {
    background: theme.palette.grey[500],
    color: 'white',
    margin: theme.spacing(4, 3),
    padding: theme.spacing(3),
    '& .MuiTypography-root': {
      color: 'white',
    },
    '& > :last-child': {
      marginBottom: 0,
    },
    '& > :first-child': {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2.5),
    },
  },
}

type HeadingStyles = {
  [x: string]: CSSProperties
}

export function getHeadingStyles(headingStyle: CSSProperties): HeadingStyles {
  return Object.fromEntries(
    [...Array(6)].map((_, i) => [`h${i + 1}`, headingStyle]),
  )
}

// Overrides type needs extending for MUI Lab components
// See https://github.com/mui-org/material-ui/issues/12164#issuecomment-564041219
export interface Overrides extends CoreOverrides {
  // Define additional lab components here as needed....
  MuiToggleButton?:
    | Partial<
        Record<ToggleButtonClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined
  MuiToggleButtonGroup?:
    | Partial<
        Record<ToggleButtonGroupClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined
}
