import { ELegacyPaymentRecipient, EPaymentRecipient, PaymentMethod } from "@aletheia/graphql";
import { isPayment } from "@aletheia/graphql/Payments/utils";
import { GIFT_AID_EXEMPT_PAYMENT_METHODS } from "../components/Donation/PaymentForm/stages/utils";
export var paymentCalculations = function(Payment) {
    var calculateFee = function calculateFee() {
        var amount = getAmount();
        var fee = 0;
        // Chariot fees are 2.9%
        if (Payment.gateway === "CHARIOT") {
            fee = amount * 0.029;
        }
        // only bother estimating fees if we don't have them already, and we have an amount
        if (Payment.gateway === "STRIPE" && Payment.paymentMethod !== PaymentMethod.Acss) {
            var recipient = Payment.recipient;
            switch(recipient){
                case ELegacyPaymentRecipient.CEA_UK:
                case EPaymentRecipient.GWWC_UK:
                    // Stripe fees for GWWC UK (European cards only)
                    fee = amount * 0.012 + 0.2;
                    break;
                case EPaymentRecipient.GWWC_US:
                case ELegacyPaymentRecipient.CEA_US:
                    // Stripe fees for GWWC US
                    fee = amount * 0.022 + 0.3;
                    break;
                case EPaymentRecipient.EAA_AU:
                case EPaymentRecipient.EAAE_AU:
                    // Stripe fees for EAA and EAAE
                    // https://stripe.com/au/pricing
                    fee = amount * 0.017 + 0.3;
                    break;
                case EPaymentRecipient.RC_CA:
                    // Stripe fees for RC
                    // https://stripe.com/ca/pricing
                    fee = amount * 0.029 + 0.3;
                    break;
                default:
                    // TODO: With NextJS > 13 use satisfies instead to get type checking:
                    // throw new Error(`Unknown recipient fee: ${recipient satisfies never}`)
                    new Error("Stripe fees for recipient are missing: ".concat(recipient));
            }
        }
        // Only estimate fees if we don't have them already, and we have an amount
        if (Payment.gateway === "STRIPE" && Payment.paymentMethod === PaymentMethod.Acss) {
            // Stripe fees for ACSS payments
            fee = amount * 0.01 + 0.4;
        }
        // NOTE: Given that GWWC has negotiated a fixed monthly costs for fees we
        // cannot directly match fees to specific transactions. GWWC will absorb the
        // GC monthly fee for now. This is something we should revisit when doing
        // our next payment methods review.
        //
        // gocardless charges 1% + £0.20 capped at £4
        // if (Payment.gateway === 'GOCARDLESS') {
        //   fee = amount * 0.01 + 0.2
        //   if (fee > 4) fee = 4
        // }
        return fee;
    };
    var getAmount = function getAmount() {
        return typeof Payment.amount === "number" ? Payment.amount : isPayment(Payment) && Payment.chargeAmount || 0;
    };
    var getFee = function getFee() {
        // strict equality, as fee could be Number 0
        if (isPayment(Payment) && (Payment.fee || Payment.fee === 0)) return Payment.fee;
        return calculateFee();
    };
    var getNet = function getNet() {
        // strict equality, as net could be Number 0
        if (isPayment(Payment) && (Payment.net || Payment.net === 0)) return Payment.net;
        return getAmount() - getFee();
    };
    var getGiftAid = function getGiftAid() {
        var ref, ref1, ref2;
        if (isPayment(Payment) && ((ref = Payment._links.giftAidClaim) === null || ref === void 0 ? void 0 : ref.addressId)) {
            return getAmount() * 0.25;
        }
        var giftAidClaimed = (ref1 = Payment.metadata) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.gift_aid) === null || ref2 === void 0 ? void 0 : ref2.claimed;
        if (!giftAidClaimed) return 0;
        if (Payment.recipient !== EPaymentRecipient.GWWC_UK) throw new Error("Gift aid claimed on non-UK donation!");
        return getAmount() * 0.25;
    };
    var getTotal = function getTotal() {
        return getAmount() + getGiftAid();
    };
    var getValue = function getValue() {
        return getNet() + getGiftAid();
    };
    var getCurrencyCode = function getCurrencyCode() {
        return typeof Payment.amount === "number" ? Payment.currencyCode || "" : isPayment(Payment) && Payment.chargeCurrencyCode || "";
    };
    return {
        amount: getAmount(),
        net: getNet(),
        fee: getFee(),
        giftAid: getGiftAid(),
        total: getTotal(),
        value: getValue(),
        currencyCode: getCurrencyCode()
    };
};
export var canHaveGiftAid = function(Payment) {
    return Payment.currencyCode === "GBP" && Payment.recipient === EPaymentRecipient.GWWC_UK && [
        "STRIPE",
        "GOCARDLESS",
        "OFFLINE"
    ].includes(Payment.gateway) && (!Payment.paymentMethod || Payment.paymentMethod && !GIFT_AID_EXEMPT_PAYMENT_METHODS.includes(Payment.paymentMethod));
};
