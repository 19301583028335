import {
  Button,
  Divider,
  FormHelperText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Properties } from 'csstype'
import React, { useState } from 'react'

import {
  AddressType,
  TAddress,
  TPerson,
  TTemporaryPerson,
  useGetAddressesQueryWithReducer,
} from '@aletheia/graphql'

import FormattedAddress from '../../formatters/Address'
import LoadingSpinner from '../LoadingSpinner'
import NewAddressDialog from './NewAddressDialog'
import { isPerson } from './types'

const useStyles = makeStyles((_theme) => ({
  root: {},
  button: {
    justifyContent: ({
      justifyContent,
    }: {
      justifyContent: Properties['justifyContent']
    }) => justifyContent,
  },
}))

export type AddressSelectorProps = {
  addressId?: string | null
  addressType?: AddressType
  onSelect: (address: TAddress) => void
  disabled?: boolean
  fullWidth?: boolean
  justifyContent?: Properties['justifyContent']
  Person: TPerson | TTemporaryPerson
  displayMostRecent?: boolean
  isOrganization?: boolean
}

const AddressSelector: React.FC<AddressSelectorProps> = ({
  addressId,
  addressType,
  onSelect,
  disabled,
  fullWidth = true,
  justifyContent = 'center',
  Person,
  displayMostRecent,
  isOrganization = false,
}) => {
  const classes = useStyles({ justifyContent })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showNewAddressDialog, setShowNewAddressDialog] = useState(false)
  const [saving, setSaving] = useState(false)

  const [
    allAddresses,
    { loading: addressesLoading, refetch: refetchAddresses },
  ] = useGetAddressesQueryWithReducer({
    variables: isPerson(Person)
      ? {
          personId: Person.id,
        }
      : {
          sessionToken: Person.sessionToken,
        },
    skip: isPerson(Person) ? !Person.id : !Person.sessionToken,
  })

  const addressesByType = addressType
    ? allAddresses?.filter((address) => address.addressType === addressType)
    : allAddresses

  const addresses = addressesByType
    ?.filter((address) => !!address.organizationName === isOrganization)
    // Order by most recent
    ?.sort((a, b) => -a.createdAt.localeCompare(b.createdAt))

  // Prioritise selected address, then most recent
  const selectedAddress =
    addresses?.find((address) => address.id === addressId) ??
    (displayMostRecent && addresses && addresses.length > 0
      ? addresses[0]
      : undefined)

  function handleShowMenu(event: React.MouseEvent<HTMLElement>) {
    if (saving) return
    setAnchorEl(event.currentTarget)
  }

  async function handleSelectAddress(Address: TAddress) {
    try {
      await setSaving(true)
      await setAnchorEl(null)
      await onSelect(Address)
    } finally {
      setSaving(false)
    }
  }

  function handleShowNewAddressDialog() {
    setShowNewAddressDialog(true)
    setAnchorEl(null)
  }

  async function handleSaveAddress(Address: TAddress) {
    try {
      await setSaving(true)
      await refetchAddresses()
      setShowNewAddressDialog(false)
      onSelect(Address)
    } finally {
      await setSaving(false)
    }
  }

  if (addressesLoading) return <LoadingSpinner small />

  return (
    <>
      {addresses?.length ? (
        <Button
          onClick={handleShowMenu}
          fullWidth={fullWidth}
          disabled={saving || disabled}
          className={classes.button}
          variant="outlined"
        >
          {selectedAddress ? (
            <FormattedAddress Address={selectedAddress} />
          ) : (
            'Select your address'
          )}
          <ExpandMoreIcon />
        </Button>
      ) : (
        <Button
          onClick={handleShowNewAddressDialog}
          fullWidth={fullWidth}
          disabled={saving || disabled}
          className={classes.button}
          variant="outlined"
        >
          {isOrganization ? 'Add organisation address' : 'Add your address'}
          <AddIcon />
        </Button>
      )}
      {saving && <FormHelperText>Saving...</FormHelperText>}

      <Menu
        id="address-selector"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {addresses?.map((address) => (
          <MenuItem
            key={address.id}
            selected={address === selectedAddress}
            onClick={() => handleSelectAddress(address)}
          >
            <FormattedAddress Address={address} />
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={handleShowNewAddressDialog}>
          Add new address <AddIcon />
        </MenuItem>
      </Menu>
      <NewAddressDialog
        Person={Person}
        open={showNewAddressDialog}
        onClose={() => setShowNewAddressDialog(false)}
        onSave={handleSaveAddress}
        addressType={addressType}
        isOrganization={isOrganization}
      />
    </>
  )
}

export default AddressSelector
