import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Grid,
  Link as MuiLink,
  makeStyles,
  TextField,
} from '@material-ui/core'
import EditLocationIcon from '@material-ui/icons/EditLocation'
import SearchIcon from '@material-ui/icons/Search'
import { Alert, Autocomplete } from '@material-ui/lab'
import React, { useState, useEffect } from 'react'

import {
  AddressType,
  TPerson,
  TTemporaryPerson,
  useCreateAddressMutation,
} from '@aletheia/graphql'
import {
  TAddress,
  useAllCountriesQueryWithReducer,
  TCountry,
} from '@aletheia/graphql'

import validate from '../../../../utils/validate'
import GoogleAddressSearch from '../GoogleAddressSearch'
import { formatGooglePlaceAsAddress } from '../GoogleAddressSearch/utils'
import LoadingSpinner from '../LoadingSpinner'
import { AddressUnsaved, isPerson } from './types'
import { individualConstraints, organizationConstraints } from './validation'

const useStyles = makeStyles((theme) => ({
  changeModeLink: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
}))

type NewAddressDialogProps = DialogProps & {
  onSave: (Address: TAddress) => Promise<void>
  Person: TPerson | TTemporaryPerson
  addressType?: AddressType
  isOrganization?: boolean
}

const NewAddressDialog: React.FC<NewAddressDialogProps> = ({
  onSave,
  Person,
  addressType,
  isOrganization = false,
  ...dialogProps
}) => {
  const classes = useStyles()
  const { onClose } = dialogProps
  if (!onClose) throw new Error('You must supply an onClose attribute')
  const [showAddressSearch, setShowAddressSearch] = useState(true)
  const [saving, setSaving] = useState(false)
  const [createAddressMutation] = useCreateAddressMutation()
  const [Address, setAddress] = useState<AddressUnsaved>({})

  const validationState: Record<string, string[]> | undefined = validate(
    Address,
    isOrganization ? organizationConstraints : individualConstraints,
  )
  const isValid = !validationState

  function handleAddressSearchChange(NewAddress: AddressUnsaved) {
    setAddress(NewAddress)
    setShowAddressSearch(false)
  }

  function handleShowAddressSearch() {
    setAddress({})
    setShowAddressSearch(true)
  }

  function handleShowAddressManualEntry() {
    setShowAddressSearch(false)
  }

  async function handleSave() {
    setSaving(true)
    try {
      const result = await createAddressMutation({
        variables: {
          ...Address,
          addressType,
          ...(isPerson(Person)
            ? {
                personId: Person.id,
              }
            : {
                sessionToken: Person.sessionToken,
              }),
        },
      })
      const NewAddress = result?.data?.mutationResult?.address as TAddress
      if (NewAddress) {
        await onSave(NewAddress)
        setAddress({})
        setShowAddressSearch(true)
      } else {
        throw new Error('No address data in query result')
      }
    } catch (err: any) {
      console.error(err.message)
    } finally {
      setSaving(false)
    }
  }

  if (!Person) return null

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Add new address</DialogTitle>
      <DialogContent>
        {showAddressSearch ? (
          <>
            <AddressSearch onChange={handleAddressSearchChange} />
            <DialogContentText
              variant="body2"
              className={classes.changeModeLink}
            >
              <MuiLink
                component="button"
                onClick={handleShowAddressManualEntry}
              >
                Enter address manually <EditLocationIcon />
              </MuiLink>
            </DialogContentText>
          </>
        ) : (
          <>
            <AddressManualEntry
              Address={Address}
              onChange={setAddress}
              validationState={validationState}
              Person={isPerson(Person) ? Person : undefined}
              isOrganization={isOrganization}
            />
            <DialogContentText
              variant="body2"
              className={classes.changeModeLink}
            >
              <MuiLink component="button" onClick={handleShowAddressSearch}>
                Search for address <SearchIcon />
              </MuiLink>
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={saving}
          onClick={(event) => onClose(event, 'escapeKeyDown')}
        >
          Cancel
        </Button>
        <Button disabled={!isValid || saving} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useAddressSearchStyles = makeStyles((theme) => ({
  root: {
    minWidth: '70vw',
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
}))

type AddressSearchProps = {
  onChange: (Address: AddressUnsaved) => void
}

const AddressSearch: React.FC<AddressSearchProps> = ({ onChange }) => {
  const classes = useAddressSearchStyles()
  function handleChange(place?: google.maps.places.PlaceResult) {
    if (!place) return
    const Address = formatGooglePlaceAsAddress(place)
    if (Address) onChange(Address)
  }

  return (
    <div className={classes.root}>
      <GoogleAddressSearch onChange={handleChange} autoFocus={true} />
    </div>
  )
}

const getAddressEntryFields: (isOrganization: boolean) => Readonly<
  {
    id: keyof AddressUnsaved
    label: string
    width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    required?: boolean
  }[]
> = (isOrganization) => {
  const nameFields = isOrganization
    ? ([
        {
          id: 'organizationName',
          label: 'Organisation Name',
          width: 12,
          required: true,
        },
      ] as const)
    : ([
        { id: 'firstName', label: 'First Name', required: true },
        { id: 'lastName', label: 'Last Name', required: true },
      ] as const)
  return [
    ...nameFields,
    {
      id: 'houseNumber',
      label: isOrganization ? 'Building Number / Name' : 'House Number / Name',
      width: 5,
      required: true,
    },
    {
      id: 'addressLine1',
      label: 'Address Line 1',
      width: 7,
      required: true,
    },
    {
      id: 'addressLine2',
      label: 'Address Line 2',
      width: 12,
    },
    {
      id: 'city',
      label: 'City',
      required: true,
    },
    {
      id: 'region',
      label: 'Region',
    },
    {
      id: 'postalCode',
      label: 'Postal Code',
      required: true,
    },
  ]
}

const useAddressManualEntryStyles = makeStyles((theme) => ({
  textField: {},
  addressFieldsGrid: {},
  errorMessage: {
    marginTop: theme.spacing(2),
  },
}))

type AddressManualEntryProps = {
  Address: AddressUnsaved
  onChange: (Address: AddressUnsaved) => void
  validationState?: Record<keyof AddressUnsaved, string[]>
  Person?: TPerson
  isOrganization?: boolean
}

const ADDRESS_SHOW_ERRORS_TIMEOUT = 10000
const ADDRESS_SHOW_FIELD_ERRORS_TIMEOUT = 20000

const AddressManualEntry: React.FC<AddressManualEntryProps> = ({
  Address,
  onChange,
  validationState,
  Person,
  isOrganization = false,
}) => {
  const classes = useAddressManualEntryStyles()
  // show errors by default if we're starting with a pre-filled address from the autocomplete
  const defaultShowErrors = Object.values(Address).filter((a) => a).length > 2
  const [showErrors, setShowErrors] = useState(defaultShowErrors)
  const [showFieldErrors, setShowFieldErrors] = useState(defaultShowErrors)
  const [Countries] = useAllCountriesQueryWithReducer()
  const isValid = !validationState

  function handleChange(mergeData: Partial<AddressUnsaved>) {
    const NewAddress = { ...Address, ...mergeData }
    onChange(NewAddress)
  }

  const handleFieldChange =
    (field: keyof AddressUnsaved) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange({
        [field]: event.target.value || undefined,
      })
    }

  const handleCountryCodeChange = (
    event: React.ChangeEvent<unknown>,
    value: TCountry | null,
  ) => handleChange({ countryCode: value?.isoAlpha2 || undefined })

  /** Show errors after a timeout */
  useEffect(() => {
    if (defaultShowErrors) return
    const globalErrors = setTimeout(() => {
      setShowErrors(true)
    }, ADDRESS_SHOW_ERRORS_TIMEOUT)
    const fieldErrors = setTimeout(() => {
      setShowFieldErrors(true)
    }, ADDRESS_SHOW_FIELD_ERRORS_TIMEOUT)
    return () => {
      clearTimeout(globalErrors)
      clearTimeout(fieldErrors)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isOrganization && Person?.firstName && Person?.lastName)
      handleChange({
        firstName: Person.firstName || undefined,
        lastName: Person.lastName || undefined,
      })
    if (isOrganization && Person?.organizationName)
      handleChange({
        organizationName: Person.organizationName || undefined,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Person])

  useEffect(() => {
    if (!isOrganization && Address.organizationName)
      handleChange({ organizationName: undefined })
    if (isOrganization && (Address.firstName || Address.lastName))
      handleChange({
        firstName: undefined,
        lastName: undefined,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrganization, Address])

  return (
    <>
      <Grid container spacing={3} className={classes.addressFieldsGrid}>
        {getAddressEntryFields(isOrganization).map((field, i) => (
          <Grid item key={field.id} md={field.width || 6}>
            {/* TODO: Use autocomplete hint */}
            {/* https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill */}
            <TextField
              id={`address-selector-${field.id}`}
              value={Address[field.id] || ''}
              onChange={handleFieldChange(field.id)}
              label={field.label}
              className={classes.textField}
              fullWidth
              required={field.required}
              autoFocus={i === 0}
              error={
                showFieldErrors &&
                Boolean(validationState && validationState[field.id])
              }
            />
          </Grid>
        ))}
        <Grid item xs={12} md={6}>
          {Countries?.length ? (
            <Autocomplete
              options={Countries}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                  label="Country"
                  error={
                    showFieldErrors && Boolean(validationState?.countryCode)
                  }
                />
              )}
              getOptionLabel={(country) =>
                typeof country === 'string' ? country : country.name
              }
              getOptionSelected={(option, value) =>
                option.isoAlpha2 === value?.isoAlpha2
              }
              value={
                Countries.find(
                  (country) => country.isoAlpha2 === Address.countryCode,
                ) || null
              }
              onChange={handleCountryCodeChange}
            />
          ) : (
            <LoadingSpinner small />
          )}
        </Grid>
      </Grid>
      {!isValid && showErrors && (
        <Alert severity="warning" className={classes.errorMessage}>
          Address is incomplete
        </Alert>
      )}
    </>
  )
}

export default NewAddressDialog
