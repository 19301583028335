const common = {
  houseNumber: {
    presence: { allowEmpty: false },
  },
  addressLine1: {
    presence: {
      message: `^Address Line 1 can't be blank`,
    },
  },
  city: {
    presence: { allowEmpty: false },
  },
  postalCode: {
    presence: { allowEmpty: false },
  },
  countryCode: {
    presence: {
      message: '^You must select a country',
    },
  },
}

export const individualConstraints = {
  firstName: {
    presence: { allowEmpty: false },
  },
  lastName: {
    presence: { allowEmpty: false },
  },
  ...common,
}

export const organizationConstraints = {
  organizationName: {
    presence: { allowEmpty: false },
  },
  ...common,
}
