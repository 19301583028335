/** Constants */ export var PAYMENT_POLLING_INITIAL_DELAY = 2000;
export var PAYMENT_POLLING_INTERVAL = 1000;
export var GOCARDLESS_REDIRECT_FLOW_POLLING_INITIAL_DELAY = 500;
/** Tests */ export var isPaymentNotPending = function(Payment) {
    return (Payment === null || Payment === void 0 ? void 0 : Payment.status) !== "PENDING";
};
isPaymentNotPending.error = Error("Payment status is still 'pending'. Please contact support.");
export var isPaymentNotIncomplete = function(Payment) {
    return (Payment === null || Payment === void 0 ? void 0 : Payment.status) !== "INCOMPLETE";
};
isPaymentNotIncomplete.error = Error("Payment status is still 'incomplete'.");
export var isPaymentIncomplete = function(Payment) {
    return (Payment === null || Payment === void 0 ? void 0 : Payment.status) === "INCOMPLETE";
};
export var isPaymentFailed = function(Payment) {
    return (Payment === null || Payment === void 0 ? void 0 : Payment.status) === "FAILED";
};
export var isPaymentWithPaymentIntentClientSecret = function(Payment) {
    var ref;
    return !!((ref = Payment === null || Payment === void 0 ? void 0 : Payment.gatewayData) === null || ref === void 0 ? void 0 : ref.payment_intent_client_secret);
};
export var isPaymentWithSubscription = function(Payment) {
    var ref;
    return !!((ref = Payment === null || Payment === void 0 ? void 0 : Payment.gatewayData) === null || ref === void 0 ? void 0 : ref.subscription);
};
