import React from 'react'

import {
  TOrganization,
  useGetOrganizationsQueryWithReducer,
} from '@aletheia/graphql'

type OrganizationNameProps = {
  Organization: TOrganization
}

const OrganizationName: React.FC<OrganizationNameProps> = ({
  Organization,
}) => {
  const { name, program } = Organization
  return (
    <span className="organization-name">
      <span className="organization-name-name">{name}</span>
      {!!program && ' '}
      {!!program && (
        <span className="organization-name-program">({program})</span>
      )}
    </span>
  )
}

export default OrganizationName

type OrganizationNameFromSlugProps = {
  slug: string
}

export const OrganizationNameFromSlug: React.FC<OrganizationNameFromSlugProps> = ({
  slug,
}) => {
  const [Organizations] = useGetOrganizationsQueryWithReducer()
  if (!Organizations?.length) return <>Loading...</>
  const Organization = Organizations.find((O) => O.slug === slug)
  if (!Organization) return <>Unknown Organization</>
  return <OrganizationName Organization={Organization} />
}
