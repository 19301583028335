import flatten from 'lodash/flatten'
import React from 'react'

import { TAddress } from '@aletheia/graphql'

type AddressFormatterDefinitionKeys = 'default' | 'US' | 'GB' | 'AU'
type TAddressKey = keyof Omit<
  TAddress,
  '__typename' | 'createdAt' | 'updatedAt'
>
type TAddressFormatterMetaKey = '%, '
type AddressFormatterDefinition = {
  [x in AddressFormatterDefinitionKeys]: (
    | TAddressKey
    | TAddressFormatterMetaKey
  )[][]
}

const formatters: AddressFormatterDefinition = {
  default: [
    ['firstName', 'lastName'],
    ['organizationName'],
    ['houseNumber', 'addressLine1'],
    ['addressLine2'],
    ['city', 'postalCode'],
    ['region'],
    ['countryCode'],
  ],
  US: [
    ['firstName', 'lastName'],
    ['organizationName'],
    ['houseNumber', 'addressLine1'],
    ['addressLine2'],
    ['city', 'region', 'postalCode'],
    ['countryCode'],
  ],
  GB: [
    ['firstName', 'lastName'],
    ['organizationName'],
    ['houseNumber', 'addressLine1'],
    ['addressLine2'],
    ['city'],
    ['postalCode'],
    ['countryCode'],
  ],
  AU: [
    ['firstName', 'lastName'],
    ['organizationName'],
    ['houseNumber', 'addressLine1'],
    ['addressLine2'],
    ['city', '%, ', 'postalCode'],
    ['region'],
    ['countryCode'],
  ],
}

const countryNicknames = {
  US: 'USA',
  GB: 'UK',
}

const regionAbbreviations = {
  US: {
    alabama: 'AL',
    alaska: 'AK',
    arizona: 'AZ',
    arkansas: 'AR',
    california: 'CA',
    colorado: 'CO',
    connecticut: 'CT',
    delaware: 'DE',
    'district of columbia': 'DC',
    florida: 'FL',
    georgia: 'GA',
    hawaii: 'HI',
    idaho: 'ID',
    illinois: 'IL',
    indiana: 'IN',
    iowa: 'IA',
    kansas: 'KS',
    kentucky: 'KY',
    louisiana: 'LA',
    maine: 'ME',
    maryland: 'MD',
    massachusetts: 'MA',
    michigan: 'MI',
    minnesota: 'MN',
    mississippi: 'MS',
    missouri: 'MO',
    montana: 'MT',
    nebraska: 'NE',
    nevada: 'NV',
    'new hampshire': 'NH',
    'new jersey': 'NJ',
    'new mexico': 'NM',
    'new york': 'NY',
    'north carolina': 'NC',
    'north dakota': 'ND',
    ohio: 'OH',
    oklahoma: 'OK',
    oregon: 'OR',
    pennsylvania: 'PA',
    'rhode island': 'RI',
    'south carolina': 'SC',
    'south dakota': 'SD',
    tennessee: 'TN',
    texas: 'TX',
    utah: 'UT',
    vermont: 'VT',
    virginia: 'VA',
    washington: 'WA',
    'west virginia': 'WV',
    wisconsin: 'WI',
    wyoming: 'WY',
  },
  AU: {
    'new south wales': 'NSW',
    queensland: 'QLD',
    'south australia': 'SA',
    tasmania: 'TAS',
    victoria: 'VIC',
    'western australia': 'WA',
    'australian capital territory': 'ACT',
    'jervis bay territory': 'JBT',
    'northern territory': 'NT',
  },
}

const DEFAULT_SEPARATOR = ', '

const metaComponentRegExp = /^%/

function isAddressFormatterMetaKey(
  part: string,
): part is TAddressFormatterMetaKey {
  return metaComponentRegExp.test(part)
}

type TAddressBlockDefinition = [TAddressKey | 'meta' | 'separator', string]

export function getFormattedAddressParts(
  Address: TAddress,
): TAddressBlockDefinition[][] {
  // pick a formatter
  const formatter =
    (Address.countryCode &&
      formatters[Address.countryCode as AddressFormatterDefinitionKeys]) ||
    formatters.default
  // get regional abbreviations for this country
  const regionAbbreviation =
    regionAbbreviations[Address.countryCode as keyof typeof regionAbbreviations]
  // map through the formatter to get each part
  return formatter
    .map((block) =>
      block
        .filter((part) => isAddressFormatterMetaKey(part) || Address[part])
        .map((part): TAddressBlockDefinition => {
          if (isAddressFormatterMetaKey(part)) return ['meta', part.slice(1)]
          let text = Address[part]
          if (!text) return ['meta', '']
          if (part === 'countryCode')
            text =
              countryNicknames[text as keyof typeof countryNicknames] || text
          if (part === 'region' && regionAbbreviation)
            text =
              regionAbbreviation[
                text.toLowerCase() as keyof typeof regionAbbreviation
              ] || text
          return [part, text]
        }),
    )
    .filter((block) => block.length)
}

export function getFormattedAddressAsString(
  Address: TAddress,
  separator = DEFAULT_SEPARATOR,
): string {
  return getFormattedAddressParts(Address)
    .map((block) =>
      block
        .reduce((prev, [part, text]) => {
          const next = part === 'meta' ? `${prev}${text}` : `${prev} ${text}`
          return next.trim()
        }, '')
        .trim(),
    )
    .join(separator)
}

export function getFormattedAddressPartsSeparated(
  Address: TAddress,
  separator = DEFAULT_SEPARATOR,
): TAddressBlockDefinition[] {
  const separatorBlock: TAddressBlockDefinition = ['separator', separator]
  const separatedBlocks: TAddressBlockDefinition[][] = flatten(
    getFormattedAddressParts(Address).map((block) => [block, [separatorBlock]]),
  ).slice(0, -1)
  return flatten(separatedBlocks)
}

const FormattedAddress: React.FC<{ Address: TAddress; separator?: string }> = ({
  Address,
  separator = DEFAULT_SEPARATOR,
}) => {
  const addressStr = getFormattedAddressAsString(Address, separator)
  return <>{addressStr}</>
}

export default FormattedAddress
