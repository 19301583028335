import React from 'react'
import { FormattedDate } from 'react-intl'

const defaults: Omit<FormattedDateProps, 'value'> = {
  month: 'long',
  year: 'numeric',
  day: 'numeric',
}

type FormattedDateProps = React.ComponentProps<typeof FormattedDate>

const PrettyDate: React.FC<FormattedDateProps> = (props) => (
  <FormattedDate {...defaults} {...props} />
)

export default PrettyDate
